import './Banner.scss';

function Banner({ lang }) {
  return (
    <section className="Banner">
      <h2>
        {lang === 'en'
          ? 'Book your consultation today'
          : `Réservez votre consultation aujourd'hui`}
      </h2>
      <a href="https://calendly.com/yxyimmigration" target="blank">
        {lang === 'en' ? 'Book Now' : 'Cliquez ici'}
      </a>
    </section>
  );
}

export default Banner;
