import { useContext, useEffect, useState } from 'react';
import Context from '../../../Context';
import './Why.scss';

function Why({ lang }) {
  const { homeUrl, jsonResult } = useContext(Context);

  // Hero Data
  const [whyData, setWhyData] = useState(null);

  useEffect(() => {
    if (jsonResult && jsonResult.why_choose) {
      setWhyData(jsonResult.why_choose);
    }
  }, [jsonResult]);

  return (
    <section className="Why">
      {lang === 'en' ? (
        <h2>
          <span style={{ color: '#EA3324' }}>WHY CHOOSE</span> <br /> YXY
          Immigration Consultant?
        </h2>
      ) : (
        <h2>
          <span style={{ color: '#EA3324' }}>POURQUOI CHOISIR</span> <br /> YXY
          Immigration Consultant?
        </h2>
      )}

      <h4>
        {whyData &&
          (lang === 'en' ? whyData.description_en : whyData.description_fr)}
      </h4>
      <div className="Why__text">
        <div className="Why__text__line"></div>
        <ul className="Why__text__items">
          {whyData &&
            (lang === 'en'
              ? JSON.parse(whyData.points_en).map((point, index) => (
                <li key={index}>{point}</li>
              ))
              : JSON.parse(whyData.points_fr).map((point, index) => (
                <li key={index}>{point}</li>
              )))}
        </ul>
      </div>
    </section>
  );
}

export default Why;
