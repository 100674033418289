import { useContext, useEffect, useRef, useState } from 'react';
import Context from '../../../Context';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import Card from './Card';
import { MdArrowCircleLeft, MdArrowCircleRight } from 'react-icons/md';
import './Clients.scss';

function Clients({ lang }) {
  const { jsonResult } = useContext(Context);
  const swiperRef = useRef();

  // HowToStart Data
  const [clientsSayData, setClientsSayData] = useState(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    if (jsonResult && jsonResult.clients_say) {
      setClientsSayData(jsonResult.clients_say);
    }
  }, [jsonResult]);

  const handleSlideChange = (swiper) => {
    setCurrentSlideIndex(swiper.realIndex);
  };

  return (
    <section className="Clients">
      {lang === 'en' ? (
        <h2>
          WHAT OUR <span style={{ color: '#EA3324' }}>CLIENTS</span> SAY
        </h2>
      ) : (
        <h2>
          CE QUE NOS <span style={{ color: '#EA3324' }}>CLIENTS </span>DISENT
        </h2>
      )}
      <Swiper
        className="Clients__carousel"
        spaceBetween={20}
        autoHeight={true}
        speed={1000}
        modules={[Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        onSlideChange={handleSlideChange}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1.5,
          },
          700: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
        }}
      >
        {clientsSayData &&
          clientsSayData.map((comment, index) => (
            <SwiperSlide key={index}>
              <Card comment={comment} lang={lang} />
            </SwiperSlide>
          ))}
      </Swiper>
      <div className="arrows">
        <MdArrowCircleLeft
          className={`arrow ${currentSlideIndex === 0 ? 'disabled' : ''}`}
          onClick={() => swiperRef.current?.slidePrev()}
        />
        <MdArrowCircleRight
          className={`arrow ${currentSlideIndex === clientsSayData?.length - 1 ? 'disabled' : ''
            }`}
          onClick={() => swiperRef.current?.slideNext()}
        />
      </div>
    </section>
  );
}

export default Clients;
