function LanguageSwitcher({ lang, langSwitcher }) {
  return (
    <div>
      <button className="lang" onClick={() => langSwitcher()}>
        {lang === 'en' ? 'FR' : 'EN'}
      </button>
    </div>
  );
}

export default LanguageSwitcher;
