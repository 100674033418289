import { useContext, useEffect, useState } from 'react';
import Context from '../../../Context';
import './AdditionalServices.scss';
import photo from './img/additional__services.jpg';

function AdditionalServices({ lang }) {
  const { jsonResult } = useContext(Context);
  const [addServicesData, setAddServices] = useState(null);

  useEffect(() => {
    if (jsonResult && jsonResult.additional_services) {
      setAddServices(jsonResult.additional_services);
    }
  }, [jsonResult]);

  return (
    <section className="AdditionalServices">
      <img src={photo} alt="" />
      {addServicesData && (
        <>
          <div className="AdditionalServices__text">
            <h2>
              {lang === 'en'
                ? 'ADDITIONAL services'
                : 'Des services supplémentaires'}{' '}
            </h2>
            {addServicesData.title1_en && (
              <div className="AdditionalServices__text__item">
                <h4>
                  {lang === 'en'
                    ? addServicesData.title1_en
                    : addServicesData.title1_fr}
                </h4>
                <p>
                  {lang === 'en'
                    ? addServicesData.text1_en
                    : addServicesData.text1_fr}
                </p>
                <a
                  href={
                    lang === 'en'
                      ? addServicesData.link_en_1
                      : addServicesData.link_fr_1
                  }
                  target="blank">
                  {lang === 'en'
                    ? addServicesData.link_name_en_1
                    : addServicesData.link_name_fr_1}
                </a>
              </div>
            )}

            {addServicesData.title2_en && (
              <div className="AdditionalServices__text__item">
                <h4>
                  {lang === 'en'
                    ? addServicesData.title2_en
                    : addServicesData.title2_fr}
                </h4>
                <p>
                  {lang === 'en'
                    ? addServicesData.text2_en
                    : addServicesData.text2_fr}
                </p>
                <a
                  href={
                    lang === 'en'
                      ? addServicesData.link_en_2
                      : addServicesData.link_fr_2
                  }
                  target="blank">
                  {lang === 'en'
                    ? addServicesData.link_name_en_2
                    : addServicesData.link_name_fr_2}
                </a>
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
}

export default AdditionalServices;
