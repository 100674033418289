import { useContext, useEffect, useState } from 'react';
import Context from '../../../Context';
import './Hero.scss';
import ellipse from './img/ellipse.svg';

function Hero({ lang }) {
  const { homeUrl, jsonResult } = useContext(Context);

  // Hero Data
  const [heroData, setHeroData] = useState(null);

  useEffect(() => {
    if (jsonResult && jsonResult.hero) {
      setHeroData(jsonResult.hero);
    }
  }, [jsonResult]);

  return (
    <section className="Hero">
      <div className="Hero__title">
        {lang === 'en' ? (
          <h1>
            YXY immigration
            <br />
            Let's fulfill your{' '}
            <span style={{ color: '#EA3324' }}>Canadian</span>
            <span>
              {' '}
              Dreams! <img src={ellipse} />
            </span>
          </h1>
        ) : (
          <h1>
            YXY immigration
            <br />
            Réalisons vos <span style={{ marginRight: '10px' }}>rêves</span>
            <span style={{ color: '#EA3324' }}>canadiens!</span>
          </h1>
        )}
      </div>

      <div className="Hero__content">
        <div className="Hero__content__img">
          <img src={`${homeUrl}/uploads/HomeHero.jpg`} alt="Photo of Julie" />
        </div>
        <div className="Hero__content__text">
          <div className="Hero__content__text__left">
            {heroData &&
              (lang === 'en' ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html: heroData.left_side_text_en,
                  }}
                />
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: heroData.left_side_text_fr,
                  }}
                />
              ))}
          </div>
          <div className="Hero__content__text__right">
            <h6>
              {heroData &&
                (lang === 'en'
                  ? heroData.right_side_title_en_1
                  : heroData.right_side_title_fr_1)}
            </h6>

            <p style={{ whiteSpace: 'pre-line' }}>
              {heroData &&
                (lang === 'en'
                  ? heroData.right_side_text_en_1
                  : heroData.right_side_text_fr_1)}
            </p>

            <a
              href={
                heroData &&
                (lang === 'en'
                  ? heroData.right_side_link_en_1
                  : heroData.right_side_link_fr_1)
              }
              target="blank"
            >
              {heroData &&
                (lang === 'en'
                  ? heroData.right_side_link_name_en_1
                  : heroData.right_side_link_name_fr_1)}
            </a>

            <p style={{ whiteSpace: 'pre-line' }}>
              {heroData &&
                (lang === 'en'
                  ? heroData.right_side_text_en_2
                  : heroData.right_side_text_fr_2)}
            </p>

            <a
              href={
                heroData &&
                (lang === 'en'
                  ? heroData.right_side_link_en_2
                  : heroData.right_side_link_fr_2)
              }
              target="blank"
            >
              {heroData &&
                (lang === 'en'
                  ? heroData.right_side_link_name_en_2
                  : heroData.right_side_link_name_fr_2)}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
